import { createSelector } from 'reselect';
import { getTrolley } from './get-items';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const getSubProducts = createSelector(
  [getTrolley],
  ({ substitutionProducts = {} } = {}) => substitutionProducts || EMPTY_OBJECT,
);

export const getSubProductsByLineNumber = createSelector(
  [getSubProducts, (state, lineNumber) => lineNumber],
  (substitutionProducts, lineNumber) => substitutionProducts[lineNumber] ?? EMPTY_ARRAY,
);

export const hasSubItems = createSelector(
  getSubProductsByLineNumber,
  subProduct => subProduct.length > 0,
);
