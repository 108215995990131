import trolleyItems from 'api/definitions/trolleyItems';
import { addDays } from 'utils/date';
import urls from 'constants/urls';
import { getPathname } from 'redux/modules/routing/selectors';
import { getSelectedBranchSelector } from 'redux/modules/slot-booking/selectors/get-selected-branch';
import { selectBranchLoadSlots } from 'redux/modules/slot-booking/actions/select-branch-load-slots';
import { getFirstSlotDay } from 'redux/modules/slot-dates/selectors';
import {
  CLEAR_LAST_REMOVED_TROLLEY_ITEM,
  REMOVE_ITEM_FROM_TROLLEY,
  TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
  TROLLEY_QUEUE_ITEM_UPDATED,
  TROLLEY_QUEUE_ITEM_UPDATING,
} from 'redux/modules/trolley/actions/types';
import { getItemById } from 'redux/modules/trolley/selectors/get-item';
import { getProductsNotAvailableOnDate } from 'redux/modules/trolley/selectors/get-items-with-restrictions';
import { getTrolleyHardConflictById } from 'redux/modules/conflicts/selectors';

export default ({ trolleyItemId, productPosition }) =>
  async (dispatch, getState) => {
    const trolleyItem = getItemById(getState(), trolleyItemId) ?? {};
    const { quantity: { uom } = {} } = trolleyItem;
    const analytics = {
      decrement: true,
      lineNumber: trolleyItem.lineNumber,
      uom,
      productPosition,
    };

    dispatch({
      trolleyItem,
      hasHardConflict: !!getTrolleyHardConflictById(getState(), trolleyItemId),
      type: REMOVE_ITEM_FROM_TROLLEY,
    });

    await dispatch({
      analytics,
      apiCall: trolleyItems.updateItem({
        trolleyItemId,
        body: { ...trolleyItem, quantity: { amount: 0, uom } },
      }),
      queue: true,
      types: [TROLLEY_QUEUE_ITEM_UPDATING, TROLLEY_QUEUE_ITEM_UPDATED],
      callback: {
        success: response => {
          const { data: { updateTrolleyItem } = {}, errors } = response;

          if (errors || !updateTrolleyItem || updateTrolleyItem.failures) {
            let error = new Error('Graph call succeeded but returned no data or an unknown error');

            if (errors?.length) {
              [error] = errors;
            }

            if (updateTrolleyItem?.failures?.length) {
              const { failures } = updateTrolleyItem;
              [error] = failures;
            }
            throw error;
          }
          return { ...updateTrolleyItem };
        },
        failure: error => {
          dispatch({
            type: CLEAR_LAST_REMOVED_TROLLEY_ITEM,
          });
          dispatch({
            error,
            type: TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
          });
        },
      },
    });

    const firstSlotDay = getFirstSlotDay(getState());
    const products = getProductsNotAvailableOnDate(getState(), addDays(firstSlotDay, -1)) ?? [];
    const isLastBlockingProductRemoved =
      products.length === 1 && products[0].product.lineNumber === trolleyItem.lineNumber;
    const slotBookingUrls = [
      urls.bookDeliverySlot,
      urls.bookEntertainingCollectionSlot,
      urls.bookGroceriesAndEntertainingCollectionSlot,
    ];
    const pathname = getPathname(getState());
    const isSlotBookingPage = slotBookingUrls.includes(pathname);

    if (isLastBlockingProductRemoved && isSlotBookingPage) {
      const branch = getSelectedBranchSelector(getState());
      setTimeout(() => dispatch(selectBranchLoadSlots(branch, true)), 2000);
    }
  };
