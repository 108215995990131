import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { getProductIdByLineNumber } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import {
  AddBold as AddIcon,
  MinusBold as MinusIcon,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { KEY_ENTER } from 'constants/keys';
import { GRM, weightOptions } from 'constants/weightOptions';
import { setTimeout, clearTimeout } from 'utils/settimeout-wrapper';
import { getClickContext } from 'utils/clickContext';
import useSupercedingTimer from 'hooks/use-superceding-timer';
import useOnClickOutside from 'hooks/use-on-click-outside';

import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import {
  messageAddToTrolley,
  messageRemoveFromTrolley,
  messageUpdateTrolley,
} from 'components/TrolleyActions/screenReaderMessagesHelper';

import useTrolleyActions from 'hooks/use-trolley-actions';
import styles from './index.scss';

const recentlyUpdatedTimeout = 2000;
const formatAmount = (desiredUom, value) => {
  const [integerPart, fraction = ''] = value.split('.');
  const truncatedValue = integerPart
    ? `${integerPart}${weightOptions[desiredUom].allowFractional ? `.${fraction.slice(0, 1)}` : ''}`
    : '';
  const skipParseFloat =
    truncatedValue === '' ||
    (weightOptions[desiredUom].allowFractional && value[value.length - 1] === '.');

  return skipParseFloat ? truncatedValue : parseFloat(truncatedValue);
};

const TrolleyControls = ({ productName, isDisabled, lineNumber }) => {
  const inputRef = useRef();
  const plusButtonRef = useRef();
  const insideRef = useRef();
  // eslint-disable-next-line
  // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
  const productId = useSelector(state => getProductIdByLineNumber(state, lineNumber), isEqual);
  const {
    desiredAmount,
    desiredUom,
    updateDesiredAmount,
    updateInTrolley,
    isEditingTrolleyAmount,
    setEditingTrolleyAmount,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
  } = useTrolleyActions({ lineNumber, productId });

  const { updateTimeout: updateFocusTimeout } = useSupercedingTimer();
  const [screenReaderMsg, setScreenReaderMsg] = useState(null);

  const [isRecentlyUpdated, setRecentlyUpdated] = useState(false);
  const [recentlyUpdatedTimer, setRecentlyUpdatedTimer] = useState(null);
  const [btnAutoFocus, setBtnAutoFocus] = useState(false);

  useEffect(() => {
    if (
      desiredAmount === optimisticTrolleyQuantityAmount &&
      isEditingTrolleyAmount &&
      isRecentlyUpdated
    ) {
      const timer = setTimeout(() => {
        setRecentlyUpdated(false);
        setEditingTrolleyAmount(false);
      }, recentlyUpdatedTimeout);
      setRecentlyUpdatedTimer(timer);
    }
  }, [
    desiredAmount,
    isRecentlyUpdated,
    isEditingTrolleyAmount,
    setRecentlyUpdated,
    setEditingTrolleyAmount,
    setRecentlyUpdatedTimer,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
  ]);

  const incrementInTrolley = useCallback(
    e => {
      updateInTrolley({
        newQuantity: {
          amount:
            optimisticTrolleyQuantityAmount + weightOptions[optimisticTrolleyQuantityUom].increment,
          uom: optimisticTrolleyQuantityUom,
        },
        clickContext: getClickContext(e),
      });
      setScreenReaderMsg(
        messageAddToTrolley(
          productName,
          optimisticTrolleyQuantityAmount,
          optimisticTrolleyQuantityUom,
        ),
      );
    },
    [
      setScreenReaderMsg,
      updateInTrolley,
      productName,
      optimisticTrolleyQuantityAmount,
      optimisticTrolleyQuantityUom,
    ],
  );

  const decrementInTrolley = useCallback(() => {
    const newAmount = (
      optimisticTrolleyQuantityAmount - weightOptions[optimisticTrolleyQuantityUom].increment
    ).toFixed(1);
    setBtnAutoFocus(true);
    updateInTrolley({
      newQuantity: {
        amount: newAmount > 0 ? newAmount : 0,
        uom: optimisticTrolleyQuantityUom,
      },
    });
    setScreenReaderMsg(
      messageRemoveFromTrolley(
        productName,
        optimisticTrolleyQuantityAmount,
        optimisticTrolleyQuantityUom,
      ),
    );
  }, [
    setScreenReaderMsg,
    productName,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
    updateInTrolley,
  ]);

  const handleUpdateInTrolley = useCallback(
    e => {
      updateInTrolley({
        newQuantity: {
          amount: desiredAmount || 0,
          uom: desiredUom,
        },
        clickContext: getClickContext(e),
      });
      setScreenReaderMsg(messageUpdateTrolley(productName, desiredAmount, desiredUom));

      if (
        desiredAmount &&
        desiredAmount <= weightOptions[desiredUom].maximum &&
        desiredAmount >= weightOptions[desiredUom].minimum
      ) {
        setRecentlyUpdated(true);
      } else {
        setEditingTrolleyAmount(false);
        setRecentlyUpdated(false);
      }
      if (desiredAmount === '' || desiredAmount === 0) {
        setEditingTrolleyAmount(false);
      }
    },
    [
      setScreenReaderMsg,
      updateInTrolley,
      productName,
      desiredAmount,
      desiredUom,
      setEditingTrolleyAmount,
    ],
  );

  const handleUpdateKeyPress = useCallback(event => {
    if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
      setBtnAutoFocus(true);
    }
  }, []);

  const handleInputKeyDown = useCallback(
    event => {
      if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
        handleUpdateInTrolley(event);
        if (desiredAmount === 0 || desiredAmount === '') {
          setBtnAutoFocus(true);
        }
      }
    },
    [desiredAmount, handleUpdateInTrolley],
  );

  const selectAmount = useCallback(
    event => {
      const {
        target: { value },
      } = event;
      if (recentlyUpdatedTimer) {
        clearTimeout(recentlyUpdatedTimer);
        setRecentlyUpdatedTimer(null);
        setEditingTrolleyAmount(false);
        setRecentlyUpdated(false);
      }

      const newAmount = formatAmount(desiredUom, value);

      if (newAmount <= weightOptions[desiredUom].maximum) {
        updateDesiredAmount(newAmount);
      }

      if (newAmount > 0 && newAmount === optimisticTrolleyQuantityAmount) {
        setEditingTrolleyAmount(false);
      } else {
        setEditingTrolleyAmount(true);
      }
    },
    [
      recentlyUpdatedTimer,
      desiredUom,
      optimisticTrolleyQuantityAmount,
      setEditingTrolleyAmount,
      updateDesiredAmount,
    ],
  );

  const handleFocus = () => inputRef.current.select();

  useEffect(() => {
    if (btnAutoFocus) {
      setBtnAutoFocus(false);
      if (isRecentlyUpdated) {
        updateFocusTimeout(() => {
          plusButtonRef.current.focus();
        }, recentlyUpdatedTimeout + 150);
      }
    }
  }, [desiredAmount, btnAutoFocus, isRecentlyUpdated, updateFocusTimeout]);

  useOnClickOutside(insideRef, () => {
    setEditingTrolleyAmount(false);
  });

  return (
    <div ref={insideRef} data-test-name={productName} data-testid="trolleyControls">
      <div className={styles.form}>
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        <label
          data-testid="inputProductAmountWrapper"
          className={classNames({
            [styles.inputWrapper]: true,
            [styles.showUom]: true,
          })}
        >
          {/* eslint-enable jsx-a11y/label-has-associated-control */}
          <input
            aria-label={`${weightOptions[desiredUom].ariaLabel} to add to trolley`}
            ref={inputRef}
            className={styles.input}
            data-testid="inputProductAmount"
            type="text"
            inputMode={desiredUom === GRM ? 'numeric' : 'decimal'}
            max={weightOptions[desiredUom].maximum}
            min={weightOptions[desiredUom].minimum}
            value={desiredAmount}
            onKeyDown={handleInputKeyDown}
            onChange={selectAmount}
            onFocus={handleFocus}
          />
          <div className={styles.inputUom}>{weightOptions[desiredUom].label}</div>
        </label>

        <>
          <div className={classNames(styles.updateBtn, { [styles.hide]: !isEditingTrolleyAmount })}>
            {isRecentlyUpdated ? (
              <div className={styles.updated}>Updated</div>
            ) : (
              <Button
                aria-label={`update ${productName} in trolley`}
                data-testid="trolleyUpdateButton"
                disabled={isDisabled}
                onClick={handleUpdateInTrolley}
                onKeyPress={handleUpdateKeyPress}
                label="Update"
                type="button"
                theme="secondary"
                width="fit"
              />
            )}
          </div>

          <div
            className={classNames(styles.btnCTA, { [styles.hide]: isEditingTrolleyAmount })}
            data-testid="trolleyMinusButtonWrapper"
          >
            <Button
              aria-label={`Remove ${productName} from trolley`}
              data-testid="trolleyMinusButton"
              disabled={isDisabled}
              onClick={decrementInTrolley}
              startIcon={<MinusIcon />}
              type="button"
              width="fit"
            />
          </div>

          <div
            className={classNames(styles.btnCTA, { [styles.hide]: isEditingTrolleyAmount })}
            data-testid="trolleyPlusButtonWrapper"
          >
            <Button
              aria-label={`Add ${productName} to trolley`}
              ref={plusButtonRef}
              data-testid="trolleyPlusButton"
              disabled={isDisabled}
              onClick={incrementInTrolley}
              startIcon={<AddIcon />}
              type="button"
              width="fit"
            />
          </div>
        </>
      </div>
      <ScreenReaderAnnouncement message={screenReaderMsg ?? ''} />
    </div>
  );
};

TrolleyControls.propTypes = {
  isDisabled: PropTypes.bool,
  productName: PropTypes.string.isRequired,
  lineNumber: PropTypes.string.isRequired,
};

TrolleyControls.defaultProps = {
  isDisabled: false,
};

export default memo(TrolleyControls);
