import { weightOptions } from 'constants/weightOptions';

const formatAmountForScreenReader = (amount, allowFractional) =>
  allowFractional ? Math.round(amount * 10) / 10 : parseInt(amount, 10);

export const amountUom = (amount, uom) => {
  const { increment = 0 } = weightOptions[uom];
  return amount <= increment ? 0 : amount - increment;
};

export const messageAddToTrolley = (productName, initialAmount, uom, newAmount) => {
  const { allowFractional, label, increment = 0 } = weightOptions[uom];
  const addedAmount = newAmount ?? increment;
  const formattedAmountInTrolley = formatAmountForScreenReader(
    initialAmount + addedAmount,
    allowFractional,
  );
  return `${addedAmount}${label} ${productName} added to trolley. ${formattedAmountInTrolley}${label} in trolley`;
};

export const messageRemoveFromTrolley = (productName, initialAmount, uom) => {
  const { allowFractional, label } = weightOptions[uom];
  const amountInTrolley = amountUom(initialAmount, uom);
  const formattedAmountInTrolley = formatAmountForScreenReader(amountInTrolley, allowFractional);
  const formattedAmountRemoved = formatAmountForScreenReader(
    initialAmount - amountInTrolley,
    allowFractional,
  );
  return `${formattedAmountRemoved}${label} ${productName} removed from trolley. ${formattedAmountInTrolley}${label} in trolley`;
};

export const messageUpdateTrolley = (productName, newAmount, uom) => {
  const { allowFractional, label = 0 } = weightOptions[uom];

  const formattedAmountInTrolley = formatAmountForScreenReader(newAmount, allowFractional);
  return `${productName} quantity updated in trolley. ${formattedAmountInTrolley}${label} in trolley`;
};
