import removeMultipleItems from 'redux/modules/trolley/actions/remove-multiple-items';
import { getTrolley } from 'redux/modules/trolley/actions/get-trolley';
import { getTrolleyItemsSetForRemoval } from '../selectors/get-trolley-items-set-for-removal';
import { CLEAR_ITEMS_SET_FOR_REMOVAL } from './types';

export function clearSwapedItems() {
  return async (dispatch, getState) => {
    const unavailableItems = getTrolleyItemsSetForRemoval(getState());
    if (unavailableItems.length > 0) {
      await dispatch(removeMultipleItems(unavailableItems));
      dispatch(getTrolley());
      dispatch({ type: CLEAR_ITEMS_SET_FOR_REMOVAL });
    }
  };
}
